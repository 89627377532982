import React from 'react'
import SlateEditor from './Editor'

function App () {
  return (
    <SlateEditor />
  )
}

export default App
