import React, { useState, useMemo, useCallback } from 'react'
import { Editor, Transforms, Text, createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import Data from './data/filtered-14pt.json'

function SlateEditor () {
  // Create a Slate editor object that won't change across renders.
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const initialValue = [
    {
      children: [
        {
          text:
            'This is editable text that you can search. As you search, it looks for matching strings of text, and adds ',
        },
        { text: 'decorations', bold: true },
        { text: ' to them in realtime.' }
      ]
    },
    {
      children: [
        { text: 'Try it out for yourself by typing in the search box above!' }
      ]
    }
  ]

  const [value, setValue] = useState(
    JSON.parse(window.localStorage.getItem('content')) || initialValue
  )

  const [colorize] = useState(true)

  const renderElement = useCallback(props => {
    switch (props.element.type) {
      case 'code':
        return <CodeElement {...props} />
      case 'centeredParagraph':
        return <CenteredElement {...props} />
      default:
        return <DefaultElement {...props} />
    }
  }, [])

  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />
  }, [])

  const removePunctuation = (s) => {
    var punctuationless = s.replace(/[.,/#!?$%^&*;:{}=\-_`'"”~()]/g, '')
    var finalString = punctuationless.replace(/\s{2,}/g, ' ')

    return finalString
  }

  const decorate = useCallback(
    ([node, path]) => {
      const ranges = []

      if (colorize && Text.isText(node)) {
        const { text } = node
        const parts = text.split(' ')
        let offset = 0

        parts.forEach((part, i) => {
          ranges.push({
            anchor: { path, offset },
            focus: { path, offset: offset + part.length },
            highlight: true,
            color: Data[removePunctuation(part.toLowerCase())]
          })

          offset += part.length + 1
        })
      }

      return ranges
    }, [colorize]
  )

  const editorActions = {
    codeBlock: {
      isActive (editor) {
        const [match] = Editor.nodes(editor, {
          match: n => n.type === 'code'
        })

        return !!match
      },

      toggle (editor) {
        const isActive = editorActions.codeBlock.isActive(editor)
        Transforms.setNodes(
          editor,
          { type: isActive ? null : 'code' },
          { match: n => Editor.isBlock(editor, n) }
        )
      }
    },

    isBoldMarkActive (editor) {
      const [match] = Editor.nodes(editor, {
        match: n => n.bold === true,
        universal: true
      })

      return !!match
    },

    toggleBoldMark (editor) {
      const isActive = editorActions.isBoldMarkActive(editor)
      Transforms.setNodes(
        editor,
        { bold: isActive ? null : true },
        { match: n => Text.isText(n), split: true }
      )
    },

    isUlineMarkActive (editor) {
      const [match] = Editor.nodes(editor, {
        match: n => n.uline === true,
        universal: true
      })

      return !!match
    },

    toggleULineMark (editor) {
      const isActive = editorActions.isUlineMarkActive(editor)
      Transforms.setNodes(
        editor,
        { uline: isActive ? null : true },
        { match: n => Text.isText(n), split: true }
      )
    },

    isItalicsMarkActive (editor) {
      const [match] = Editor.nodes(editor, {
        match: n => n.italics === true,
        universal: true
      })

      return !!match
    },

    toggleItalicsMark (editor) {
      const isActive = editorActions.isItalicsMarkActive(editor)
      Transforms.setNodes(
        editor,
        { italics: isActive ? null : true },
        { match: n => Text.isText(n), split: true }
      )
    }
  }

  const onKeyDownHandler = useCallback(event => {
    if (!event.ctrlKey & !event.metaKey & !event.altlKey) {
      if (event.key === '+') {
        event.preventDefault()
        editor.insertText('and')
      }
      return
    }

    if (event.ctrlKey) {
      switch (event.key) {
        // When "`" is pressed, keep our existing code block logic.
        case '`': {
          event.preventDefault()
          editorActions.codeBlock.toggle(editor)
          break
        }

        default: {
          break
        }
      }
    }

    if (event.metaKey) {
      switch (event.key) {
        // When "B" is pressed, bold the text in the selection.
        case 'b': {
          event.preventDefault()
          editorActions.toggleBoldMark(editor)
          break
        }

        // When "U" is pressed, bold the text in the selection.
        case 'u': {
          event.preventDefault()
          editorActions.toggleULineMark(editor)
          break
        }

        // When "I" is pressed, bold the text in the selection.
        case 'i': {
          event.preventDefault()
          editorActions.toggleItalicsMark(editor)
          break
        }

        default: {
          break
        }
      }
    }
  }, [editorActions, editor])

  return (
    <div className='h-screen p-8 text-lg text-gray-700'>
      <Slate
        className=''
        editor={editor}
        value={value}
        onChange={value => {
          setValue(value)
          const content = JSON.stringify(value)
          window.localStorage.setItem('content', content)
        }}
      >
        <Editable
          spellCheck
          autoFocus
          className='h-full p-2' // border rounded shadow-md
          decorate={decorate}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDownHandler}
        />
      </Slate>
    </div>
  )
}

const CodeElement = props => {
  return (
    <pre className='block bg-gray-900 text-gray-100 rounded' {...props.attributes}>
      <code className='p-2'>{props.children}</code>
    </pre>
  )
}

const DefaultElement = props => {
  return <p {...props.attributes}>{props.children}</p>
}

const CenteredElement = props => {
  return <p className='text-center' {...props.attributes}>{props.children}</p>
}

const Leaf = props => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf.bold ? 'bold' : 'normal', textDecoration: props.leaf.uline ? 'underline' : '', fontStyle: props.leaf.italics ? 'italic' : 'normal', color: props.leaf.color }}
    >
      {props.children}
    </span>
  )
}

export default SlateEditor
